<template>
  <div class="home">
    <Environment v-if="environment != 'production'"/>
    <div> 
      <cmsHeader class="indexheader" />
      <div class="container">
      
        <h1>Welcome</h1>
          <p>Welcome to the <b>Jacinta Gallant</b> Content Management System</p>
          <router-link to="/cms/event/list"><h3>Events</h3></router-link>
          <p>Add, edit, or remove events and manage registrations</p>
          <router-link to="/cms/blog/list"><h3>Resources</h3></router-link>
          <p>Add, edit, or remove Resource posts</p>
          <router-link to="/cms/trainers/list"><h3>Trainers</h3></router-link>
          <p>Add, edit, or remove Trainers</p>
      
      </div><!--container-->
      <cmsFooter />
    </div><!--container-fluid-->
  </div>
</template>

<script>
// Components
import cmsHeader from '../../components/cms/cmsHeader.vue'
import cmsFooter from '../../components/cms/cmsFooter.vue'

export default {
  name: 'cmsHome',
  components: {
    cmsHeader,
    cmsFooter,
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  },
  created() {
    // Load the store with available trainers
    this.$store.dispatch('fetchCMSTrainers')
    // Load the store with the events list
    this.$store.dispatch('fetchCMSEventsList')
    // Load the store with the events
    this.$store.dispatch('fetchCMSEvents')
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: left;
}
</style>